(function(exports, Enum) {

    'use strict';


    exports.containerShape = {
        types : {
            oval : {
                vertical            : 1,
                horizontal          : 2,
                upright             : 3
            },
            rectangular             : 4,
            cylindrical : {
                vertical            : 5,
                horizontal          : 6,
                horizontalDished    : 7
            }
        },
        shapeRequiresHeight : function(shapeId){
            switch(shapeId){
                case(1):
                case(2):
                case(3):
                case(4):
                    return true;
                default:
                    return false;
            }
        },
        shapeRequiresLength: function(shapeId){
            switch(shapeId){
                case(1):
                case(2):
                case(3):
                case(4):
                case(5):
                    return true;
                default:
                    return false;
            }
        },
        shapeRequiresWidth : function(shapeId){
            switch(shapeId){
                case(1):
                case(2):
                case(3):
                case(4):
                    return true;
                default:
                    return false;
            }
        },
        shapeRequiresDiameter : function(shapeId){
            switch(shapeId){
                case(5):
                case(6):
                case(7):
                    return true;
                default:
                    return false;
            }
        },
        shapeWidthIsHeight: function (shapeId) {
            switch (shapeId) {
                case(6):
                case(7):
                    return true;
                default:
                    return false;
            }
        }
    };

    exports.tankMonitorSettings = {
        SURL : 1 ,
        SPORT : 2 ,
        AES : 3 ,
        PASS : 4 ,
        FWURL : 5 ,
        TCPT : 6 ,
        TCPR : 7 ,
        SDAYS : 8 ,
        SSTART : 9 ,
        SOFF : 10,
        RST : 11,
        SCTRL : 12,
    };


    exports.roles = {
        public: 'public',
        user: 'user',
        sysadmin: 'sysadmin',
        admin: 'admin',
        qa: 'qa'
    };

    exports.accessLevels = {
        public: 'public',
        anon: 'anon',
        user: 'user',
        admin: 'admin',
        sysadmin: 'sysadmin'
    };

    exports.widgetPackages = {
        adept: 1,
        angusAdmin: 2,
        ar: 3,
        blossman: 4,
        bsg: 5,
        customers: 6,
        delivery: 7,
        fds: 8,
        finance: 9,
        fuelpak: 10,
        paygo: 11,
        petro: 12,
        santaBudgetVar: 13,
        service: 14,
        snapShots: 15,
        spes: 16,
        tms: 17,
        weather: 18
    }

    exports.includeExcludeOnlyOptions = {
        include: 'Y',
        exclude: 'N',
        only: 'O' 
    };

    exports.yesNoOnlyDisplay = {
        Y: 'Yes',
        N: 'No',
        O: 'Only'

    }

    exports.snapshotGoalTypes = {
        snapshot: 'SnapshotGoal',
        gainLoss: 'GainLossGoal',
        gain: 'GainGoal',
        loss: 'LossGoal',
        delivery: 'DeliveryGoal',
        service: 'ServiceGoal',
        serviceRevenue: 'ServiceRevenueGoal',
        degreeDay: 'DegreeDayGoal'
    };

    exports.exceptionTypeGroups = {
        paygo : {
            display : 'Paygo',
            keys : [1,2,3,4,5]
        },
        tms : {
            display : 'TMS',
            keys : [6]
        }
    };
    exports.exceptionThresholdGroups = {
        tms : { exceptionThresholdKeys : [1,2,3]}
    };

    exports.tmsExceptionThresholds = {
        poorSonar : {
            name : 'Paygo Monitor Poor Sonar Reading',
            key : 1,
            unitDesc : 'Wireless signal strength. (0-10)'
        },
        levelRise : {
            name : 'Paygo Monitor Level Rise',
            key : 2,
            unitDesc : 'Percentage rise from previous reading.'
        },
        levelDrop : {
            name : 'Paygo Monitor Excessive Level Drop',
            key : 3,
            unitDesc : 'Percentage drop from previous reading.'
        },
        criticalLow : {
            name : 'Paygo Monitor Critical Low Level',
            key : 4,
            unitDesc : 'Percentage of usable tank size.'
        }
    }


    exports.billStatus = new Enum({
        'new': {
            display: 'New',
            id: 1,
        },
        'approved': {
            display: 'Approved',
            id: 2,
        },
        'integratedToBOS': {
            display: 'Integrated To Back Office System',
            id: 3,
        },
        'integratedToIntacct': {
            display: 'Integrated To Accounting System',
            id: 4,
        },
        'complete': {
            display: 'Complete',
            id: 5,
        },
        'void': {
            display: 'Void',
            id: 6,
        }

    });

    exports.billType = new Enum({
        'cycle': {
            display: 'Billing Cycle',
            id: 1,
        },
        'final': {
            display: 'Final Bill',
            id: 2,
        }
    });

    exports.modules = new Enum({
        'brite': {
            display: 'Brite'
        },
        'paygo': {
            display: 'Paygo'
        }
    });

    exports.addressType = new Enum({
        'headquarters': {
            display: 'Headquarters'
        },
        'division': {
            display: 'Division'
        },
        'billing': {
            display: 'Billing'
        },
        'shipping': {
            display: 'Shipping'
        },
        'vpn': {
            display: 'VPN'
        }
    });

    exports.hierarchyType = new Enum({
        'division': {
            display: 'Division'
        },
        'deliveryCenter': {
            display: 'Delivery Center'
        },
        'serviceCenter': {
            display: 'Service Center'
        },
        'dealer': {
            display: 'Dealer'
        }
    });

    exports.widgetAccess = new Enum({
        'full': {
            display: 'Full Access'
        },
        'none': {
            display: 'No Access'
        },
        'readonly': {
            display: 'Readonly Access'
        }
    });

    exports.paymentMethod = new Enum({
        'creditCard': {
            display: 'Credit Card'
        },
        'invoice': {
            display: 'Invoice'
        },
        'ach': {
            display: 'ACH'
        }
    });

    exports.contactType = new Enum({
        'primary': {
            display: 'Primary'
        },
        'technical': {
            display: 'Technical'
        }
    });

    exports.phoneType = new Enum({
        'business': {
            display: 'Business'
        },
        'mobile': {
            display: 'Mobile'
        },
        'fax': {
            display: 'Fax'
        }
    });

    exports.registrationStatus = {
        connectionSetup: 1,
        live: 2,
        deleted: 3
    }

    exports.periodIndicators = new Enum({
        'lastDeliveryDate': {
            display: 'Last Delivery Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDate': {
            display: 'Custom Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.paygoPeriodIndicators = new Enum({
        'priorMonth': {
            display: 'Last Month'
        },
        'prior6Months': {
            display: 'Last 6 Months'
        },
        'prior12Months': {
            display: 'Last 12 Months'
        },
        'customDate': {
            display: 'Custom Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.invoicePeriodIndicators = new Enum({
        'lastInvoiceDate': {
            display: 'Last Invoice Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDate': {
            display: 'Custom Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.forecastingPeriodIndicators = new Enum({
        'thisWeek': {
            display: 'This Week',
            periodType: 'day'
        },
        'nextWeek': {
            display: 'Next Week',
            periodType: 'day'
        },
        'thisMonth': {
            display: 'This Month',
            periodType: 'week'
        },
        'nextMonth': {
            display: 'Next Month',
            periodType: 'week'
        },
        'nextSixMonths': {
            display: 'Next 6 Months',
            periodType: 'month'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.yearsPeriodIndicator = new Enum({
        'oneYear': {
            display: 'One Year',
        },
        'twoYears': {
            display: 'Two Years',
        },
        'threeYears': {
            display: 'Three Years'
        }
    });

    exports.marginPeriodIndicators = new Enum({
        'lastAcquistionDate': {
            display: 'Last Acquisition / Termination Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.acquisitionIndicators = new Enum({
        'lastAcquistionDate': {
            display: 'Last Acquisition Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.terminationIndicators = new Enum({
        'lastAcquistionDate': {
            display: 'Last Termination Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.servicePeriodIndicators = new Enum({
        'lastPostingDate': {
            display: 'Last Posting Date'
        },
        'prior7Days': {
            display: 'Prior 7 Days'
        },
        'prior14Days': {
            display: 'Prior 14 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDate': {
            display: 'Custom Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.budgetPeriodIndicators = new Enum({
        'currentMonth': {
            display: 'Current Month'
        },
        'currentYear': {
            display: 'Current Fiscal Year'
        }
    });

    exports.weatherType = new Enum({
        'clearDay': {
            icon: 'clear-day'
        },
        'clearNight': {
            icon: 'clear-night'
        },
        'rain': {
            icon: 'rain'
        },
        'snow': {
            icon: 'snow'
        },
        'sleet': {
            icon: 'sleet'
        },
        'wind': {
            icon: 'wind'
        },
        'fog': {
            icon: 'fog'
        },
        'cloudy': {
            icon: 'cloudy'
        },
        'partlyCloudyDay': {
            icon: 'partly-cloudy-day'
        },
        'partlyCloudyNight': {
            icon: 'partly-cloudy-night'
        },
        'hail': {
            icon: 'hail'
        },
        'thunderstorm': {
            icon: 'thunderstorm'
        },
        'tornado': {
            icon: 'tornado'
        }
    });

    exports.precipitationType = new Enum({
        'none': {
            icon: ''
        },
        'rain': {
            icon: 'rain'
        },
        'snow': {
            icon: 'snow'
        },
        'sleet': {
            icon: 'sleet'
        },
        'hail': {
            icon: 'hail'
        }
    });

    exports.countries = new Enum({
        'unitedStates': {
            display: 'United States',
            code: 'US',
            stateProvinceLabel: 'State',
            postalPattern: '^\\d{5}(-\d{4})?$'
        },
        'canada': {
            display: 'Canada',
            code: 'CA',
            stateProvinceLabel: 'Province',
            postalPattern: '^[A-Za-z][0-9][A-Za-z]\\s?[0-9][A-Za-z][0-9]$'
        }
    });

    exports.stateProvinces = new Enum({
        'alabama': {
            display: 'Alabama',
            country: 'unitedStates',
            code: 'AL'
        },
        'alaska': {
            display: 'Alaska',
            country: 'unitedStates',
            code: 'AK'
        },
        'arizona': {
            display: 'Arizona',
            country: 'unitedStates',
            code: 'AZ'
        },
        'arkansas': {
            display: 'Arkansas',
            country: 'unitedStates',
            code: 'AR'
        },
        'california': {
            display: 'California',
            country: 'unitedStates',
            code: 'CA'
        },
        'colorado': {
            display: 'Colorado',
            country: 'unitedStates',
            code: 'CO'
        },
        'connecticut': {
            display: 'Connecticut',
            country: 'unitedStates',
            code: 'CT'
        },
        'delaware': {
            display: 'Delaware',
            country: 'unitedStates',
            code: 'DE'
        },
        'district of columbia': {
            display: 'District Of Columbia',
            country: 'unitedStates',
            code: 'DC'
        },
        'florida': {
            display: 'Florida',
            country: 'unitedStates',
            code: 'FL'
        },
        'georgia': {
            display: 'Georgia',
            country: 'unitedStates',
            code: 'GA'
        },
        'hawaii': {
            display: 'Hawaii',
            country: 'unitedStates',
            code: 'HI'
        },
        'idaho': {
            display: 'Idaho',
            country: 'unitedStates',
            code: 'ID'
        },
        'illinois': {
            display: 'Illinois',
            country: 'unitedStates',
            code: 'IL'
        },
        'indiana': {
            display: 'Indiana',
            country: 'unitedStates',
            code: 'IN'
        },
        'iowa': {
            display: 'Iowa',
            country: 'unitedStates',
            code: 'IA'
        },
        'kansas': {
            display: 'Kansas',
            country: 'unitedStates',
            code: 'KS'
        },
        'kentucky': {
            display: 'Kentucky',
            country: 'unitedStates',
            code: 'KY'
        },
        'louisiana': {
            display: 'Louisiana',
            country: 'unitedStates',
            code: 'LA'
        },
        'maine': {
            display: 'Maine',
            country: 'unitedStates',
            code: 'ME'
        },
        'maryland': {
            display: 'Maryland',
            country: 'unitedStates',
            code: 'MD'
        },
        'massachusetts': {
            display: 'Massachusetts',
            country: 'unitedStates',
            code: 'MA'
        },
        'michigan': {
            display: 'Michigan',
            country: 'unitedStates',
            code: 'MI'
        },
        'minnesota': {
            display: 'Minnesota',
            country: 'unitedStates',
            code: 'MN'
        },
        'mississippi': {
            display: 'Mississippi',
            country: 'unitedStates',
            code: 'MS'
        },
        'missouri': {
            display: 'Missouri',
            country: 'unitedStates',
            code: 'MO'
        },
        'montana': {
            display: 'Montana',
            country: 'unitedStates',
            code: 'MT'
        },
        'nebraska': {
            display: 'Nebraska',
            country: 'unitedStates',
            code: 'NE'
        },
        'nevada': {
            display: 'Nevada',
            country: 'unitedStates',
            code: 'NV'
        },
        'new hampshire': {
            display: 'New Hampshire',
            country: 'unitedStates',
            code: 'NH'
        },
        'new jersey': {
            display: 'New Jersey',
            country: 'unitedStates',
            code: 'NJ'
        },
        'new mexico': {
            display: 'New Mexico',
            country: 'unitedStates',
            code: 'NM'
        },
        'new york': {
            display: 'New York',
            country: 'unitedStates',
            code: 'NY'
        },
        'north carolina': {
            display: 'North Carolina',
            country: 'unitedStates',
            code: 'NC'
        },
        'north dakota': {
            display: 'North Dakota',
            country: 'unitedStates',
            code: 'ND'
        },
        'ohio': {
            display: 'Ohio',
            country: 'unitedStates',
            code: 'OH'
        },
        'oklahoma': {
            display: 'Oklahoma',
            country: 'unitedStates',
            code: 'OK'
        },
        'oregon': {
            display: 'Oregon',
            country: 'unitedStates',
            code: 'OR'
        },
        'pennsylvania': {
            display: 'Pennsylvania',
            country: 'unitedStates',
            code: 'PA'
        },
        'rhode island': {
            display: 'Rhode Island',
            country: 'unitedStates',
            code: 'RI'
        },
        'south carolina': {
            display: 'South Carolina',
            country: 'unitedStates',
            code: 'SC'
        },
        'south dakota': {
            display: 'South Dakota',
            country: 'unitedStates',
            code: 'SD'
        },
        'tennessee': {
            display: 'Tennessee',
            country: 'unitedStates',
            code: 'TN'
        },
        'texas': {
            display: 'Texas',
            country: 'unitedStates',
            code: 'TX'
        },
        'utah': {
            display: 'Utah',
            country: 'unitedStates',
            code: 'UT'
        },
        'vermont': {
            display: 'Vermont',
            country: 'unitedStates',
            code: 'VT'
        },
        'virginia': {
            display: 'Virginia',
            country: 'unitedStates',
            code: 'VA'
        },
        'washington': {
            display: 'Washington',
            country: 'unitedStates',
            code: 'WA'
        },
        'west virginia': {
            display: 'West Virginia',
            country: 'unitedStates',
            code: 'WV'
        },
        'wisconsin': {
            display: 'Wisconsin',
            country: 'unitedStates',
            code: 'WI'
        },
        'wyoming': {
            display: 'Wyoming',
            country: 'unitedStates',
            code: 'WY'
        },
        'alberta': {
            display: 'Alberta',
            country: 'canada',
            code: 'AB'
        },
        'british columbia': {
            display: 'British Columbia',
            country: 'canada',
            code: 'BC'
        },
        'manitoba': {
            display: 'Manitoba',
            country: 'canada',
            code: 'MB'
        },
        'new brunswick': {
            display: 'New Brunswick',
            country: 'canada',
            code: 'NB'
        },
        'newfoundland and labrador': {
            display: 'Newfoundland and Labrador',
            country: 'canada',
            code: 'NL'
        },
        'nova scotia': {
            display: 'Nova Scotia',
            country: 'canada',
            code: 'NS'
        },
        'ontario': {
            display: 'Ontario',
            country: 'canada',
            code: 'ON'
        },
        'prince edward island': {
            display: 'Prince Edward Island',
            country: 'canada',
            code: 'PE'
        },
        'quebec': {
            display: 'Quebec',
            country: 'canada',
            code: 'QC'
        },
        'saskatchewan': {
            display: 'Saskatchewan',
            country: 'canada',
            code: 'SK'
        },
        'northwest territories': {
            display: 'Northwest Territories',
            country: 'canada',
            code: 'NT'
        },
        'nunavut': {
            display: 'Nunavut',
            country: 'canada',
            code: 'NU'
        },
        'yukon': {
            display: 'Yukon',
            country: 'canada',
            code: 'YT'
        }
    });

    exports.deliveryTypeCategory = new Enum({
        'automaticDeliveries': {
            id: 1
        },
        'willCallDeliveries': {
            id: 2
        }
    });

    exports.includeExcludeOnly = new Enum({
        'include': {
            display: 'Include',
            id: 'Y'
        },
        'exclude': {
            display: 'Exclude',
            id: 'N'
        },
        'only': {
            display: 'Only',
            id: 'O'
        }
    });

    exports.yesNoOnly = new Enum ({
        'yes': {
            display: 'Yes',
            id: 'Y'
        },
        'no': {
            display: 'No',
            id: 'N'
        },
        'only': {
            display: 'Only',
            id: 'O'
        }
    });

    exports.serviceCallCategory = new Enum({
        'installation': {
            display: 'Installation',
            id: 1
        },
        'a/c': {
            display: 'A/C',
            id: 2
        },
        'oil': {
            display: 'Oil',
            id: 3
        }
    });

    exports.calculationSource = new Enum({
        'brite': {
            display: 'BRITE'
        },
        'bos': {
            display: 'BOS'
        }
    });

    exports.commodityIndex = new Enum({
        'HO': {
            display: 'Heating Oil',
            symbol: 'HO',
            id: 1
        },
        'CL': {
            display: 'Crude Oil',
            symbol: 'CL',
            id: 6
        },
        'B0': {
            display: 'Propane, Mt. Belvieu',
            symbol: 'B0',
            id: 2
        },
        '8K': {
            display: 'Propane, Conway',
            symbol: '8K',
            id: 3
        },
        'NG': {
            display: 'Natural Gas',
            symbol: 'NG',
            id: 4
        },
        'RB': {
            display: 'Gasoline RBOB',
            symbol: 'RB',
            id: 5
        },
        'RT': {
            display: 'Gasoline RBOB',
            symbol: 'RT',
            id: 8
        },
        'MBCC': {
            display: 'Mt. Belvieu Cash',
            symbol: 'MBCC',
            id: 7
        }
    });

    exports.driverPerformanceMetric = new Enum({
        'unitsPerStop': {
            display: 'Units/Stop',
            id: 1
        },
        'stopsPerHour': {
            display: 'Stops/Hour',
            id: 2
        },
        'milesPerStop': {
            display: 'Miles/Stop',
            id: 3
        },
        'unitsPerHour': {
            display: 'Units/Hour',
            id: 4
        },
        'grossMarginPerHour': {
            display: 'Gross Margin/Hour',
            id: 5
        }
    });

    exports.pricePlan = new Enum({
        'capped': {
            display: 'Cap'
        },
        'fixed': {
            display: 'Fixed'
        },
        'variable': {
            display: 'Variable'
        }
    });

    exports.desiredDelivery = new Enum({
        'bosOptimal': {
            display: 'Back-office-system Optimal Delivery'
        },
        'userDefined': {
            display: 'Custom Percentage of Tank Size'
        }
    });

    exports.months = new Enum({
        'january': {
            display: 'January',
            number: 1
        },
        'february': {
            display: 'February',
            number: 2
        },
        'march': {
            display: 'March',
            number: 3
        },
        'april': {
            display: 'April',
            number: 4
        },
        'may': {
            display: 'May',
            number: 5
        },
        'june': {
            display: 'June',
            number: 6
        },
        'july': {
            display: 'July',
            number: 7
        },
        'august': {
            display: 'August',
            number: 8
        },
        'september': {
            display: 'September',
            number: 9
        },
        'october': {
            display: 'October',
            number: 10
        },
        'november': {
            display: 'November',
            number: 11
        },
        'december': {
            display: 'December',
            number: 12
        }
    });

    exports.widgets = new Enum({
        subscriberRegistration: {
            id: '546400134b7a11bc71dc63f4',
            code: 'subReg',
            name: 'Subscriber Accounts'
        },
        paygoExceptions: {
            id: '546400134b7a11bc71dc63f5',
            code: 'paygoExceptions',
            name: 'Paygo Exceptions'
        },
        driverPerformance: {
            id: '546400134b7a11bc71dc63f6',
            code: 'driverPerf',
            name: 'Driver Performance'
        },
        deliveryPerformance: {
            id: '546400134b7a11bc71dc63f7',
            code: 'deliveryPerf',
            name: 'Delivery Performance'
        },
        paygoCustomers: {
            id: '546400134b7a11bc71dc63f8',
            code: 'paygoCustRec',
            name: 'Paygo Customers'
        },
        closingQuotes: {
            id: '546400134b7a11bc71dc63f9',
            code: 'closingQuotes',
            name: 'Closing Quotes'
        },
        delayedQuotes: {
            id: '546400134b7a11bc71dc63fa',
            code: 'delayedQuotes',
            name: 'Delayed Quotes'
        },
        forecast: {
            id: '546400134b7a11bc71dc63fb',
            code: 'weather',
            name: '10-Day Forecast'
        },
        marginAnalysis: {
            id: '546400134b7a11bc71dc63fc',
            code: 'marginAnalysis',
            name: 'Margin Analysis'
        },
        marginComponents: {
            id: '546400134b7a11bc71dc62fc',
            code: 'marginComponents',
            name: 'Margin Components'
        },
        accountGainsLosses: {
            id: '546400134b7a11bc71dc63fd',
            code: 'acctGainLoss',
            name: 'Account Gains & Losses'
        },
        tankGainsLosses: {
            id: '546400134b7a11bc71dc63fe',
            code: 'acctGainLossByTank',
            name: 'Tank Gains & Losses'
        },
        accountGainsBySource: {
            id: '546400134b7a11bc71dc63ff',
            code: 'acctGainBySrc',
            name: 'Account Gains by Source'
        },
        accountLossesByReason: {
            id: '546400134b7a11bc71dc6400',
            code: 'acctLossByRsn',
            name: 'Account Losses by Reason'
        },
        hddTracker: {
            id: '546400134b7a11bc71dc6401',
            code: 'hddtracker',
            name: 'Heating Degree Days'
        },
        avgDeliveryByTankSize: {
            id: '546400134b7a11bc71dc6402',
            code: 'avgGalByTankSize',
            name: 'Average Delivery by Tank Size'
        },
        budgetVariance: {
            id: '546400134b7a11bc71dc6403',
            code: 'budgetVar',
            name: 'Budget Variance'
        },
        santaBudgetVariance: {
            id: '546400134b7a11bc71dc6304',
            code: 'santaBudgetVar',
            name: 'Santa Fuel - Budget Variance'
        },
        santaPriorPeriodVariance: {
            id: '546400134b7a11bc71dc6034',
            code: 'santaPriorPeriodVar',
            name: 'Santa Fuel - Prior Period Variance'
        },
        propaneVar: {
            id: '546400134b7a11bc71dc6341',
            code: 'propaneVar',
            name: 'Propane Variance'
        },
        // arSummary: { //Thompson Version
        //     id: '546400134b7a11bc71dc6404',
        //     code: 'arSummary',
        //     name: 'A/R Summary'
        // },
        salesReport: {
            id: '546400134b7a11bc71dc6349',
            code: 'salesReport',
            name: 'Sales Report'
        },
        marginToBudget: {
            id: '546400134b7a11bc71dc6350',
            code: 'marginToBudget',
            name: 'Margin To Budget'
        },
        volumeToBudget: {
            id: '546400134b7a11bc71dc6351',
            code: 'volumeToBudget',
            name: 'Volume To Budget'
        },
        growthToBudget: {
            id: '546400134b7a11bc71dc6352',
            code: 'growthToBudget',
            name: 'Growth To Budget'
        },
        arSummary: {
            id: '546400134b7a11bca1dc6814',
            code: 'arSummary',
            name: 'A/R Summary'
        },
        companyProfileAccounts: {
            id: '546400134b7a11bc71dc6405',
            code: 'custProfAnalysis',
            name: 'Company Profile - Accounts'
        },
        companyProfileTanks: {
            id: '546400134b7a11bc71dc6406',
            code: 'tankProfAnalysis',
            name: 'Company Profile - Tanks'
        },
        companyProfilePricePlans: {
            id: '546400134b7a11bc71dc6407',
            code: 'pricePlanProfAnalysis',
            name: 'Company Profile - Price Plans'
        },
        paygoAdminBilling: {
            id: '546400134b7a11bc71dc6408',
            code: 'paygoAdminBilling',
            name: 'Paygo Billing - Administrator'
        },
        paygoAdminCustRec: {
            id: '546400134b7a11bc71dc6409',
            code: 'paygoAdminCustRec',
            name: 'Paygo Customer Records - Administrator'
        },
        excessCalls: {
            id: '546400134b7a11bc71dc6411',
            code: 'excessCalls',
            name: 'Excess Calls'
        },
        serviceCalls: {
            id: '546400134b7a11bc71dc6410',
            code: 'serviceCalls',
            name: 'Service Calls'
        },
        serviceRevenue: {
            id: '546400134b7a11bc71dc6412',
            code: 'serviceRevenue',
            name: 'Service Revenue'
        },
        callbackAnalysis: {
            id: '546400134b7a11bc71dc6413',
            code: 'callbackAnalysis',
            name: 'Callback Analysis'
        },
        serviceRework: {
            id: '546400134b7a11bc71dc6415',
            code: 'serviceRework',
            name: 'Service Rework'
        },
        liquidFuels: {
            id: '546400134b7a11bc71dc6414',
            code: 'liquidFuels',
            name: 'Liquid Fuels Volume'
        },
        etlStatus: {
            id: '546400134b7a11bc71dc6416',
            code: 'etlStatus',
            name: 'ETL Status'
        },
        tankUtil: {
            id: '546400134b7a11bc71dc6418',
            code: 'tankUtil',
            name: 'Propane Tank Utilization'
        },
        paygoTMSAdmin: {
            id: '546400134b7a11bc71dc6419',
            code: 'paygoTMSAdmin',
            name: 'TMS Admin - Customers'
        },
        degreeDayMetrics: {
            id: '61e996f8c105e368a4199a2c',
            code: 'degreeDayMetrics',
            name: 'Degree Day Metrics'
        },
        deliveryForecasting: {
            id: '546400134b7a11bc71dc6420',
            code: 'deliveryForecasting',
            name: 'Delivery Forecasting'
        },
        adeptPerformanceAnalysis: {
            id: '546400134b7a11bc71dc6421',
            code: 'adeptPerformanceAnalysis',
            name: 'ADEPT Performance Analysis'
        }
    });
    exports.invoicePeriodIndicators = new Enum({
        'lastInvoiceDate': {
            display: 'Last Invoice Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDate': {
            display: 'Custom Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.marginPeriodIndicators = new Enum({
        'lastAcquistionDate': {
            display: 'Last Acquisition / Termination Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.acquisitionIndicators = new Enum({
        'lastAcquistionDate': {
            display: 'Last Acquisition Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.terminationIndicators = new Enum({
        'lastAcquistionDate': {
            display: 'Last Termination Date'
        },
        'prior15Days': {
            display: 'Prior 15 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.servicePeriodIndicators = new Enum({
        'lastPostingDate': {
            display: 'Last Posting Date'
        },
        'prior7Days': {
            display: 'Prior 7 Days'
        },
        'prior14Days': {
            display: 'Prior 14 Days'
        },
        'prior30Days': {
            display: 'Prior 30 Days'
        },
        'currentMonth': {
            display: 'Current Month'
        },
        'fiscalYearToDate': {
            display: 'Fiscal Year to Date'
        },
        'yearToDate': {
            display: 'Year to Date'
        },
        'customDate': {
            display: 'Custom Date'
        },
        'customDateRange': {
            display: 'Custom Date Range'
        }
    });

    exports.budgetPeriodIndicators = new Enum({
        'currentMonth': {
            display: 'Current Month'
        },
        'currentYear': {
            display: 'Current Fiscal Year'
        }
    });

    exports.budgetPeriodIndicator = new Enum({
        'currentMonth': {
            display: 'Current Month'
        },
        'currentYear': {
            display: 'Current Fiscal Year'
        }
    });

    exports.weatherType = new Enum({
        'clearDay': {
            icon: 'clear-day'
        },
        'clearNight': {
            icon: 'clear-night'
        },
        'rain': {
            icon: 'rain'
        },
        'snow': {
            icon: 'snow'
        },
        'sleet': {
            icon: 'sleet'
        },
        'wind': {
            icon: 'wind'
        },
        'fog': {
            icon: 'fog'
        },
        'cloudy': {
            icon: 'cloudy'
        },
        'partlyCloudyDay': {
            icon: 'partly-cloudy-day'
        },
        'partlyCloudyNight': {
            icon: 'partly-cloudy-night'
        },
        'hail': {
            icon: 'hail'
        },
        'thunderstorm': {
            icon: 'thunderstorm'
        },
        'tornado': {
            icon: 'tornado'
        }
    });

    exports.precipitationType = new Enum({
        'none': {
            icon: ''
        },
        'rain': {
            icon: 'rain'
        },
        'snow': {
            icon: 'snow'
        },
        'sleet': {
            icon: 'sleet'
        },
        'hail': {
            icon: 'hail'
        }
    });

    exports.countries = new Enum({
        'unitedStates': {
            display: 'United States',
            code: 'US',
            stateProvinceLabel: 'State',
            postalPattern: '^\\d{5}(-\d{4})?$'
        },
        'canada': {
            display: 'Canada',
            code: 'CA',
            stateProvinceLabel: 'Province',
            postalPattern: '^[A-Za-z][0-9][A-Za-z]\\s?[0-9][A-Za-z][0-9]$'
        }
    });

    exports.stateProvinces = new Enum({
        'alabama': {
            display: 'Alabama',
            country: 'unitedStates',
            code: 'AL'
        },
        'alaska': {
            display: 'Alaska',
            country: 'unitedStates',
            code: 'AK'
        },
        'arizona': {
            display: 'Arizona',
            country: 'unitedStates',
            code: 'AZ'
        },
        'arkansas': {
            display: 'Arkansas',
            country: 'unitedStates',
            code: 'AR'
        },
        'california': {
            display: 'California',
            country: 'unitedStates',
            code: 'CA'
        },
        'colorado': {
            display: 'Colorado',
            country: 'unitedStates',
            code: 'CO'
        },
        'connecticut': {
            display: 'Connecticut',
            country: 'unitedStates',
            code: 'CT'
        },
        'delaware': {
            display: 'Delaware',
            country: 'unitedStates',
            code: 'DE'
        },
        'district of columbia': {
            display: 'District Of Columbia',
            country: 'unitedStates',
            code: 'DC'
        },
        'florida': {
            display: 'Florida',
            country: 'unitedStates',
            code: 'FL'
        },
        'georgia': {
            display: 'Georgia',
            country: 'unitedStates',
            code: 'GA'
        },
        'hawaii': {
            display: 'Hawaii',
            country: 'unitedStates',
            code: 'HI'
        },
        'idaho': {
            display: 'Idaho',
            country: 'unitedStates',
            code: 'ID'
        },
        'illinois': {
            display: 'Illinois',
            country: 'unitedStates',
            code: 'IL'
        },
        'indiana': {
            display: 'Indiana',
            country: 'unitedStates',
            code: 'IN'
        },
        'iowa': {
            display: 'Iowa',
            country: 'unitedStates',
            code: 'IA'
        },
        'kansas': {
            display: 'Kansas',
            country: 'unitedStates',
            code: 'KS'
        },
        'kentucky': {
            display: 'Kentucky',
            country: 'unitedStates',
            code: 'KY'
        },
        'louisiana': {
            display: 'Louisiana',
            country: 'unitedStates',
            code: 'LA'
        },
        'maine': {
            display: 'Maine',
            country: 'unitedStates',
            code: 'ME'
        },
        'maryland': {
            display: 'Maryland',
            country: 'unitedStates',
            code: 'MD'
        },
        'massachusetts': {
            display: 'Massachusetts',
            country: 'unitedStates',
            code: 'MA'
        },
        'michigan': {
            display: 'Michigan',
            country: 'unitedStates',
            code: 'MI'
        },
        'minnesota': {
            display: 'Minnesota',
            country: 'unitedStates',
            code: 'MN'
        },
        'mississippi': {
            display: 'Mississippi',
            country: 'unitedStates',
            code: 'MS'
        },
        'missouri': {
            display: 'Missouri',
            country: 'unitedStates',
            code: 'MO'
        },
        'montana': {
            display: 'Montana',
            country: 'unitedStates',
            code: 'MT'
        },
        'nebraska': {
            display: 'Nebraska',
            country: 'unitedStates',
            code: 'NE'
        },
        'nevada': {
            display: 'Nevada',
            country: 'unitedStates',
            code: 'NV'
        },
        'new hampshire': {
            display: 'New Hampshire',
            country: 'unitedStates',
            code: 'NH'
        },
        'new jersey': {
            display: 'New Jersey',
            country: 'unitedStates',
            code: 'NJ'
        },
        'new mexico': {
            display: 'New Mexico',
            country: 'unitedStates',
            code: 'NM'
        },
        'new york': {
            display: 'New York',
            country: 'unitedStates',
            code: 'NY'
        },
        'north carolina': {
            display: 'North Carolina',
            country: 'unitedStates',
            code: 'NC'
        },
        'north dakota': {
            display: 'North Dakota',
            country: 'unitedStates',
            code: 'ND'
        },
        'ohio': {
            display: 'Ohio',
            country: 'unitedStates',
            code: 'OH'
        },
        'oklahoma': {
            display: 'Oklahoma',
            country: 'unitedStates',
            code: 'OK'
        },
        'oregon': {
            display: 'Oregon',
            country: 'unitedStates',
            code: 'OR'
        },
        'pennsylvania': {
            display: 'Pennsylvania',
            country: 'unitedStates',
            code: 'PA'
        },
        'rhode island': {
            display: 'Rhode Island',
            country: 'unitedStates',
            code: 'RI'
        },
        'south carolina': {
            display: 'South Carolina',
            country: 'unitedStates',
            code: 'SC'
        },
        'south dakota': {
            display: 'South Dakota',
            country: 'unitedStates',
            code: 'SD'
        },
        'tennessee': {
            display: 'Tennessee',
            country: 'unitedStates',
            code: 'TN'
        },
        'texas': {
            display: 'Texas',
            country: 'unitedStates',
            code: 'TX'
        },
        'utah': {
            display: 'Utah',
            country: 'unitedStates',
            code: 'UT'
        },
        'vermont': {
            display: 'Vermont',
            country: 'unitedStates',
            code: 'VT'
        },
        'virginia': {
            display: 'Virginia',
            country: 'unitedStates',
            code: 'VA'
        },
        'washington': {
            display: 'Washington',
            country: 'unitedStates',
            code: 'WA'
        },
        'west virginia': {
            display: 'West Virginia',
            country: 'unitedStates',
            code: 'WV'
        },
        'wisconsin': {
            display: 'Wisconsin',
            country: 'unitedStates',
            code: 'WI'
        },
        'wyoming': {
            display: 'Wyoming',
            country: 'unitedStates',
            code: 'WY'
        },
        'alberta': {
            display: 'Alberta',
            country: 'canada',
            code: 'AB'
        },
        'british columbia': {
            display: 'British Columbia',
            country: 'canada',
            code: 'BC'
        },
        'manitoba': {
            display: 'Manitoba',
            country: 'canada',
            code: 'MB'
        },
        'new brunswick': {
            display: 'New Brunswick',
            country: 'canada',
            code: 'NB'
        },
        'newfoundland and labrador': {
            display: 'Newfoundland and Labrador',
            country: 'canada',
            code: 'NL'
        },
        'nova scotia': {
            display: 'Nova Scotia',
            country: 'canada',
            code: 'NS'
        },
        'ontario': {
            display: 'Ontario',
            country: 'canada',
            code: 'ON'
        },
        'prince edward island': {
            display: 'Prince Edward Island',
            country: 'canada',
            code: 'PE'
        },
        'quebec': {
            display: 'Quebec',
            country: 'canada',
            code: 'QC'
        },
        'saskatchewan': {
            display: 'Saskatchewan',
            country: 'canada',
            code: 'SK'
        },
        'northwest territories': {
            display: 'Northwest Territories',
            country: 'canada',
            code: 'NT'
        },
        'nunavut': {
            display: 'Nunavut',
            country: 'canada',
            code: 'NU'
        },
        'yukon': {
            display: 'Yukon',
            country: 'canada',
            code: 'YT'
        }
    });

    exports.deliveryTypeCategory = new Enum({
        'automaticDeliveries': {
            id: 1
        },
        'willCallDeliveries': {
            id: 2
        }
    });

    exports.includeExcludeOnly = new Enum({
        'include': {
            display: 'Include',
            id: 'Y'
        },
        'exclude': {
            display: 'Exclude',
            id: 'N'
        },
        'only': {
            display: 'Only',
            id: 'O'
        }
    });

    exports.serviceCallCategory = new Enum({
        'installation': {
            display: 'Installation',
            id: 1
        },
        'a/c': {
            display: 'A/C',
            id: 2
        },
        'oil': {
            display: 'Oil',
            id: 3
        }
    });

    exports.calculationSource = new Enum({
        'brite': {
            display: 'BRITE'
        },
        'bos': {
            display: 'BOS'
        }
    });

    exports.commodityIndex = new Enum({
        'HO': {
            display: 'Heating Oil',
            symbol: 'HO',
            id: 1
        },
        'CL': {
            display: 'Crude Oil',
            symbol: 'CL',
            id: 6
        },
        'B0': {
            display: 'Propane, Mt. Belvieu',
            symbol: 'B0',
            id: 2
        },
        '8K': {
            display: 'Propane, Conway',
            symbol: '8K',
            id: 3
        },
        'NG': {
            display: 'Natural Gas',
            symbol: 'NG',
            id: 4
        },
        'RB': {
            display: 'Gasoline RBOB',
            symbol: 'RB',
            id: 5
        },
        'RT': {
            display: 'Gasoline RBOB',
            symbol: 'RT',
            id: 8
        },
        'MBCC': {
            display: 'Mt. Belvieu Cash',
            symbol: 'MBCC',
            id: 7
        }
    });

    exports.driverPerformanceMetric = new Enum({
        'unitsPerStop': {
            display: 'Units/Stop',
            id: 1
        },
        'stopsPerHour': {
            display: 'Stops/Hour',
            id: 2
        },
        'milesPerStop': {
            display: 'Miles/Stop',
            id: 3
        },
        'unitsPerHour': {
            display: 'Units/Hour',
            id: 4
        },
        'grossMarginPerHour': {
            display: 'Gross Margin/Hour',
            id: 5
        }
    });

    exports.pricePlan = new Enum({
        'capped': {
            display: 'Cap'
        },
        'fixed': {
            display: 'Fixed'
        },
        'variable': {
            display: 'Variable'
        }
    });

    exports.desiredDelivery = new Enum({
        'bosOptimal': {
            display: 'Back-office-system Optimal Delivery'
        },
        'userDefined': {
            display: 'Custom Percentage of Tank Size'
        }
    });

    exports.months = new Enum({
        'january': {
            display: 'January',
            number: 1
        },
        'february': {
            display: 'February',
            number: 2
        },
        'march': {
            display: 'March',
            number: 3
        },
        'april': {
            display: 'April',
            number: 4
        },
        'may': {
            display: 'May',
            number: 5
        },
        'june': {
            display: 'June',
            number: 6
        },
        'july': {
            display: 'July',
            number: 7
        },
        'august': {
            display: 'August',
            number: 8
        },
        'september': {
            display: 'September',
            number: 9
        },
        'october': {
            display: 'October',
            number: 10
        },
        'november': {
            display: 'November',
            number: 11
        },
        'december': {
            display: 'December',
            number: 12
        }
    });

    exports.widgets = new Enum({
        // subscriberRegistration: {
        //     id: '546412034b7a11bc71dc63f4',
        //     code: 'fdsServiceMargin',
        //     name: 'Service Sales'
        // },
        subscriberRegistration: {
            id: '546400134b7a11bc71dc63f4',
            code: 'subReg',
            name: 'Subscriber Accounts'
        },
        paygoExceptions: {
            id: '546400134b7a11bc71dc63f5',
            code: 'paygoExceptions',
            name: 'Paygo Exceptions'
        },
        driverPerformance: {
            id: '546400134b7a11bc71dc63f6',
            code: 'driverPerf',
            name: 'Driver Performance'
        },
        deliveryPerformance: {
            id: '546400134b7a11bc71dc63f7',
            code: 'deliveryPerf',
            name: 'Delivery Performance'
        },
        paygoCustomers: {
            id: '546400134b7a11bc71dc63f8',
            code: 'paygoCustRec',
            name: 'Paygo Customers'
        },
        closingQuotes: {
            id: '546400134b7a11bc71dc63f9',
            code: 'closingQuotes',
            name: 'Closing Quotes'
        },
        delayedQuotes: {
            id: '546400134b7a11bc71dc63fa',
            code: 'delayedQuotes',
            name: 'Delayed Quotes'
        },
        forecast: {
            id: '546400134b7a11bc71dc63fb',
            code: 'weather',
            name: '10-Day Forecast'
        },
        marginAnalysis: {
            id: '546400134b7a11bc71dc63fc',
            code: 'marginAnalysis',
            name: 'Margin Analysis'
        },
        marginComponents: {
            id: '546400134b7a11bc71dc62fc',
            code: 'marginComponents',
            name: 'Margin Components'
        },
        accountGainsLosses: {
            id: '546400134b7a11bc71dc63fd',
            code: 'acctGainLoss',
            name: 'Account Gains & Losses'
        },
        tankGainsLosses: {
            id: '546400134b7a11bc71dc63fe',
            code: 'acctGainLossByTank',
            name: 'Tank Gains & Losses'
        },
        accountGainsBySource: {
            id: '546400134b7a11bc71dc63ff',
            code: 'acctGainBySrc',
            name: 'Account Gains by Source'
        },
        accountLossesByReason: {
            id: '546400134b7a11bc71dc6400',
            code: 'acctLossByRsn',
            name: 'Account Losses by Reason'
        },
        hddTracker: {
            id: '546400134b7a11bc71dc6401',
            code: 'hddtracker',
            name: 'Heating Degree Days'
        },
        avgDeliveryByTankSize: {
            id: '546400134b7a11bc71dc6402',
            code: 'avgGalByTankSize',
            name: 'Average Delivery by Tank Size'
        },
        budgetVariance: {
            id: '546400134b7a11bc71dc6403',
            code: 'budgetVar',
            name: 'Budget Variance'
        },
        santaPriorPeriodVariance: {
            id: '546400134b7a11bc71dc6034',
            code: 'santaPriorPeriodVar',
            name: 'Santa Fuel - Prior Period Variance'
        },
        santaBudgetVariance: {
            id: '546400134b7a11bc71dc6304',
            code: 'santaBudgetVar',
            name: 'Santa Fuel - Budget Variance'
        },
        propaneVar: {
            id: '546400134b7a11bc71dc6341',
            code: 'propaneVar',
            name: 'Propane Variance'
        },
        salesReport: {
            id: '546400134b7a11bc71dc6349',
            code: 'salesReport',
            name: 'Sales Report'
        },
        marginToBudget: {
            id: '546400134b7a11bc71dc6350',
            code: 'marginToBudget',
            name: 'Margin To Budget'
        },
        volumeToBudget: {
            id: '546400134b7a11bc71dc6351',
            code: 'volumeToBudget',
            name: 'Volume To Budget'
        },
        growthToBudget: {
            id: '546400134b7a11bc71dc6352',
            code: 'growthToBudget',
            name: 'Growth To Budget'
        },
        // arSummary: { //Thompson Version
        //     id: '546400134b7a11bc71dc6404',
        //     code: 'arSummary',
        //     name: 'A/R Summary'
        // },
        arSummary: {
            id: '546400134b7a11bca1dc6814',
            code: 'arSummary',
            name: 'A/R Summary'
        },
        companyProfileAccounts: {
            id: '546400134b7a11bc71dc6405',
            code: 'custProfAnalysis',
            name: 'Company Profile - Accounts'
        },
        companyProfileTanks: {
            id: '546400134b7a11bc71dc6406',
            code: 'tankProfAnalysis',
            name: 'Company Profile - Tanks'
        },
        companyProfilePricePlans: {
            id: '546400134b7a11bc71dc6407',
            code: 'pricePlanProfAnalysis',
            name: 'Company Profile - Price Plans'
        },
        paygoAdminBilling: {
            id: '546400134b7a11bc71dc6408',
            code: 'paygoAdminBilling',
            name: 'Paygo Billing - Administrator'
        },
        paygoAdminCustRec: {
            id: '546400134b7a11bc71dc6409',
            code: 'paygoAdminCustRec',
            name: 'Paygo Customer Records - Administrator'
        },
        excessCalls: {
            id: '546400134b7a11bc71dc6411',
            code: 'excessCalls',
            name: 'Excess Calls'
        },
        serviceCalls: {
            id: '546400134b7a11bc71dc6410',
            code: 'serviceCalls',
            name: 'Service Calls'
        },
        serviceRevenue: {
            id: '546400134b7a11bc71dc6412',
            code: 'serviceRevenue',
            name: 'Service Revenue'
        },
        callbackAnalysis: {
            id: '546400134b7a11bc71dc6413',
            code: 'callbackAnalysis',
            name: 'Callback Analysis'
        },
        serviceRework: {
            id: '546400134b7a11bc71dc6415',
            code: 'serviceRework',
            name: 'Service Rework'
        },
        liquidFuels: {
            id: '546400134b7a11bc71dc6414',
            code: 'liquidFuels',
            name: 'Liquid Fuels Volume'
        },
        etlStatus: {
            id: '546400134b7a11bc71dc6416',
            code: 'etlStatus',
            name: 'ETL Status'
        },
        tankUtil: {
            id: '546400134b7a11bc71dc6418',
            code: 'tankUtil',
            name: 'Propane Tank Utilization'
        },
        paygoTMSAdmin: {
            id: '546400134b7a11bc71dc6419',
            code: 'paygoTMSAdmin',
            name: 'TMS Admin - Customers'
        },
        paygoTankMonitorPerformance: {
            id: '547100134b7a11bc71dc6420',
            code: 'paygoTankMonitorPerformance',
            name: 'Tank Monitor Performance'
        },
        tmsInstallSummary: {
            id: '547100134b7a11ab92dc6420',
            code: 'tmsInstallSummary',
            name: 'Monitor Install Summary'
        }
    });

    exports.widgetCode = {
        acctLossesBySrc: 'acctLossesBySrc'
    };

    exports.systemSettingCode = new Enum({
        monthActivePropane: {
            id: 4,
            code: 'MonthActive_Propane'
        },
        monthActiveNonPropane: {
            id: 5,
            code: 'MonthActive_NonPropane'
        },
        runOutsPercentagePropane: {
            id: 6,
            code: 'RunOutsPercentage_Propane'
        },
        runOutsPercentageNonPropane: {
            id: 7,
            code: 'RunOutsPercentage_NonPropane'
        },
        potentialRunOutTypePropane: {
            id: 8,
            code: 'PotentialRunOutType_Propane'
        },
        potentialRunOutTypeNonPropane: {
            id: 9,
            code: 'PotentialRunOutType_NonPropane'
        }
    });


    exports.costBasisOptions = new Enum({
        'bos': {
            display: 'Purchases from BOS',
            id: 1
        },
        'index': {
            display: 'Index',
            id: 2
        },
        'transactionCost': {
            display: 'Transaction Cost',
            id: 3
        }
    });

    exports.costIndexOptions = new Enum({
        'nymex': {
            display: 'NYMEX',
            description: 'New York Mercantile Exchange',
            id: 1
        },
        'belvieu': {
            display: 'Belvieu',
            description: 'Mont Belvieu',
            id: 2
        }
    });

    exports.costDiffOptions = new Enum({
        'variable': {
            display: 'Variable',
            id: 1
        },
        'contract': {
            display: 'Contract',
            id: 2
        },
        'other': {
            display: 'Other Contract Adjustment',
            id: 3
        }
    });

    exports.gainLossType = new Enum({
        gain: {
            id: 1,
            display: 'Gain'
        },
        toAuto: {
            id: 2,
            display: 'To Auto'
        },
        toWillCall: {
            id: 3,
            display: 'To Will-Call'
        },
        loss: {
            id: 4,
            display: 'Loss'
        }
    });


    exports.exceptionStatus = new Enum({
        new: {
            id: 1,
            display: 'New'
        },
        resolved: {
            id: 2,
            display: 'Resolved'
        },
        ignore: {
            id: 3,
            display: 'Ignored'
        },
        pending: {
            id: 4,
            display: 'Pending'
        }
    });

    exports.integrationSettings = new Enum({
        liquid: {
            id: 2,
            code: 'ADDsLiquidPostingCode'
        },
        payment: {
            id: 3,
            code: 'ADDsPaymentPostingCodes'
        },
        fdsProductSales: {
            id: 52,
            code: 'FDSProductCategorySalesCodes'
        },
        division: {
            id: 32,
            code: 'EsysDivisionIdentifier'
        },
        addsDivision: {
            id: 48,
            code: 'AddsTradeClassSourceTable'
        },
        cargasMonitoredTanks: {
            id: 56,
            code: 'MonitoredTankSourceDeliveryTypeKeyOverride'
        },
        fdsDegreeDayResetDate: {
            id: 54,
            code: 'FDSDegreeDayStartMonthDay'
        }
    });


    exports.passwordRequirements = new Enum({
        minLength: 8,
        maxLength: 20
    });

    exports.hedgeTypes = new Enum({
        wetBarrel: {
            display: 'Wet Barrel',
            description: 'Wet Barrel Contract',
            id: 1
        },
        swap: {
            display: 'Swap',
            description: 'Swap',
            id: 2
        },
        call: {
            display: 'Call',
            description: 'Call Option',
            id: 3
        },
        put: {
            display: 'Put',
            description: 'Put Option',
            id: 4
        },
    });

    exports.serviceExtendedPeriodIndicators = new Enum({
        prior45Days: {
            display: '45 Days',
            id: 1,
            daySubtraction: 45
        },
        prior90Days: {
            display: '90 Days',
            id: 2,
            daySubtraction: 90
        },
        prior180Days: {
            display: '180 Days',
            id: 3,
            daySubtraction: 180
        },
        priorYear: {
            display: '1 Year',
            id: 4,
            daySubtraction: 365
        },
        customDateRange: {
            display: 'Custom Date Range',
            id: 5,
            daySubtraction: 0
        }
    });

    exports.analysisPeriodIndicators = new Enum({
        lastPostingDate: {
            display: 'Last Posting Date',
            id: 8,
            daySubtraction: 0
        },
        prior7Days: {
            display: 'Last 7 Days',
            id: 2,
            daySubtraction: 45
        },
        prior30Days: {
            display: 'Last 30 Days',
            id: 1,
            daySubtraction: 45
        },
        currentMonth: {
            display: 'Current Month',
            id: 3,
            daySubtraction: 90
        },
        priorMonth: {
            display: 'Prior Month',
            id: 4,
            daySubtraction: 180
        },
        fiscalYearToDate: {
            display: 'Fiscal Year To Date',
            id: 5,
            daySubtraction: 0
        },
        yearToDate: {
            display: 'Year To Date',
            id: 6,
            daySubtraction: 0
        },
        customDateRange: {
            display: 'Custom Date Range',
            id: 7,
            daySubtraction: 0
        }
    });


    exports.reworkPeriodIndicators = new Enum({
        prior10Days: {
            display: 'Last 10 Days',
            id: 1,
            daySubtraction: 45
        },
        currentMonth: {
            display: 'Current Month',
            id: 2,
            daySubtraction: 90
        },
        priorMonth: {
            display: 'Prior Month',
            id: 3,
            daySubtraction: 180
        },
        fiscalYearToDate: {
            display: 'Fiscal Year To Date',
            id: 4,
            daySubtraction: 0
        },
        yearToDate: {
            display: 'Year To Date',
            id: 5,
            daySubtraction: 0
        },
        customDateRange: {
            display: 'Custom Date Range',
            id: 6,
            daySubtraction: 0
        }
    });

    exports.analysisDrillOptions = new Enum({
        calls: {
            display: 'Calls',
            id: 1
        },
        callbacks: {
            display: 'Callbacks',
            id: 2
        },
        chargeable: {
            display: 'Chargable Callbacks',
            id: 3
        },
        nonChargeable: {
            display: 'Non-Chargable Callbacks',
            id: 4
        }
    });

    exports.reworkDrillOptions = new Enum({
        calls: {
            display: 'Calls',
            id: 1
        },
        reworks: {
            display: 'Reworks',
            id: 2
        },
    });

    exports.serviceRevenueCategories = new Enum({
        contract: {
            display: 'Contract',
            id: 2
        },
        installation: {
            display: 'Installation',
            id: 1
        },
        nonContract: {
            display: 'Non-Contract',
            id: 3
        },
        // all: {
        //  display: 'All',
        // }
    });

    exports.snapShotGainLossProductCategories = new Enum({
        companyOil: {
            display: 'Company Oil',
            id: 1,
            default: true
        },
        heatUSAOil: {
            display: 'Heat USA Oil',
            id: 2,
            default: true
        },
        otherOil: {
            display: 'Other Oil',
            id: 3,
            default: true
        },
        companyPropane: {
            display: 'Company Propane',
            id: 4,
            default: false
        },
        heatUSAPropane: {
            display: 'Heat USA Propane',
            id: 5,
            default: false
        },
        otherPropane: {
            display: 'Other Propane',
            id: 6,
            default: false
        }
    });

    (function(LayerOriginType) {
        LayerOriginType[LayerOriginType["DELIVERY"] = 1] = "DELIVERY";
        LayerOriginType[LayerOriginType["PARTIAL_DELIVERY"] = 2] = "PARTIAL_DELIVERY";
        LayerOriginType[LayerOriginType["CONSUMER_OWNED"] = 3] = "CONSUMER_OWNED";
    })(exports.LayerOriginType || (exports.LayerOriginType = {}));


    exports.PaygoProductCategory = new Enum({
        heatingOil: {
            display: '#2 Heating Oil',
            id: 1
        },
        propane: {
            display: 'Propane',
            id: 2
        },
        bioHeat: {
            display: 'Bio Heat',
            id: 3
        },
        kerosene: {
            display: 'Kerosene',
            id: 4
        }
    });


    exports.deliveryGoalTypes = [
        'dropSize',
        'runouts',
        'gallonsPerHour',
        'stopsPerDay'
    ];

    exports.gainLossGoalTypes = [
        'gains',
        'losses'
    ];

    exports.serviceGoalTypes = [
        'serviceCalls',
        'serviceCallsPerDay',
        'serviceTuneups'
    ];

    exports.degreeDayGoalTypes = [
        'degreeDays'
    ];

    exports.serviceRevenueGoalTypes = [
        'serviceRevenue'
    ];

    exports.importHeaders = [{
        name: 'File Name',
        isHidable: false,
        propClass: 'text-center clickable',
        propName: 'fileName',
        isSortable: true
    }, {
        name: 'Import Date',
        isHidable: false,
        propClass: 'text-center clickable',
        propName: 'lastUpdated',
        isSortable: true
    }, {
        name: 'Status',
        isHidable: false,
        propClass: 'text-center clickable',
        propName: 'status',
        isSortable: true
    }, {
        name: 'Message Count',
        isHidable: false,
        propClass: 'text-center clickable',
        propName: 'messageCount',
        isSortable: true
    }, {
        name: 'Messages',
        isHidable: false,
        propClass: 'text-center clickable',
        propName: 'message',
        isSortable: true
    }, {
        name: '',
        isHidable: false,
        propClass: '',
        propName: '',
        isSortable: false
    }
    ];
    
    exports.fileImportDataTypes = {
        timesheetRobison: {
            templateURL: 'Angus-Energy-Timesheet-Template.xlsx',
            dataTypeKey: 1,
            product: 'brite',
            operation: 'timesheets'
        },
        closingQuotes: {
            templateURL: "Angus-Energy-Closing-Quotes-Template.xlsx",
            dataTypeKey: 2,
            product: 'brite',
            operation: 'closing-quotes'
        },
        monitorInventory: {
            templateURL: "monitor-inventory.xlsx",
            dataTypeKey: 3,
            product: 'ams',
            operation: 'monitor-inventory'
        }
    };

    exports.StringComparisonTypes = {
        exact: 1,
        start: 2,
        any: 3,
        end: 4
    }

    exports.graphColors = ['#42a5f5', '#81C784', '#e57b1e', '#1ee5de', '#FDD835', '#B39DDB', '#388e3d', '#e51e25'];
    
})(typeof exports === 'undefined' ? this.constants = {} : exports, Enum);

